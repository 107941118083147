import {buildAPIClient} from '../../api';
import { initializeStorage, $revConfig as revConfig } from '../../rev/config/storage';
import { resizeDisplayListReviews } from '../../rev/widget/resize';
import { resetPageStack } from '../../rev/widget/navigation';

import App from '../../rev/widget/App';

(function(){
  if(typeof String.prototype.replaceAll == "undefined") {
    String.prototype.replaceAll = function(match, replace){
      return this.replace(new RegExp(match, "g"), () => replace);
    }
  }

  function openPageOrIndex(e, page) {
    switch(page) {
      case 'index':
        resetPageStack();
        break;
      default:
        resetPageStack();
    }
  }

  var _iframe=(function(){
    window.addEventListener("message",function(e){
      switch(e.data.msg_action){
        case "event.opened":
          if (e.data.msg_options.page) {
            openPageOrIndex(e, e.data.msg_options.page);
          }
          break;
        case "event.resize":
          resizeDisplayListReviews();
          break;
        default:
          console.warn("Unimplemented cmd", e);
          break;
      }
    });

    return function(msg,opts){
      window.parent.postMessage({msg_action: msg, msg_options: opts}, '*');
    };
  })();

  var _API = buildAPIClient({
    $appConfig: revConfig,
    includeShopParamOnRequests: true
  });

  window._init = async () => {
    document.body.id = 'revDisplayWrapper';

    await initializeStorage(_API);

    document.body.replaceChildren(); // Clear existing content
    new App({
      target: document.body,
      context: new Map([
        ['api', _API],
        ['iframe', _iframe]
      ])
    });

    window.postMessage({ msg_action: 'app.started' });
  }
})();
