<script>
  import SealCheck from '../../common/components/icons/SealCheck.svelte';
  import PlayButton from '../../common/components/icons/PlayButton.svelte';
  import ReviewStars from '../../common/components/ReviewStars.svelte';
  import { revConfig } from '../../config/storage';
  import { translations } from '../../config/translations';
  import { renderDate, _truncate } from '../../../util';

  function openReviewModal(){
    window.parent.postMessage({msg_action: "ba.ratings.open_review", msg_options: {review: review}}, '*');
  }

  function resize(){
    window.postMessage({msg_action: "event.resize"}, '*');
  }

  export let review;
  let image = review.image_urls ? review.image_urls[0] : null;
  let cloudflareStream = review.cloudflare_stream_urls ? review.cloudflare_stream_urls[0] : null;
  let thumbnail = image || cloudflareStream?.thumbnail;
</script>

<div class="review-grid-item">
  <a href="{'javascript:void(0)'}" on:click={openReviewModal}>
    <div class="review-card">
      {#if thumbnail}
        <div class="review-media">
          {#if cloudflareStream}
            <div class="play-button-container">
              <div class="play-button-background">
                <PlayButton size=32 />
              </div>
            </div>
          {/if}
          <img src="{thumbnail}" style="background:#fff;" alt="{review.id}" on:load={() => resize()}>
        </div>
      {/if}
      <div class="review-details">
        <div class="review-header">
          <div class="author-name-w-details">
            <div class="author-name">
              {review.author}
            </div>

            {#if $revConfig.widget_settings.display_verified_badge_enabled && review.verified}
              <div class="verified-badge" title="Verified Purchase">
                <SealCheck size=16 fill="#000000" />
              </div>
            {/if}
          </div>
          {#if $revConfig.widget_settings.display_review_date_enabled}
            <div class="date">{renderDate(review.rated_at, $revConfig.widget_settings.display_date_type)}</div>
          {/if}
        </div>
        <ReviewStars stars={review.stars}/>
        <div class="review-text">{_truncate(review.body, 250)}</div>
        {#if review.reply && review.reply_is_public}
          <hr style="margin: 1em 0; border: 1px solid;" />
          <div class="review-reply">
            <div class="review-reply-text-intro">{$translations.shop_replied.replace('{{shop_name}}', window.parent.Tydal.global_config.shop.name)}</div>
            <div class="review-reply-body">{_truncate(review.reply, 250)}</div>
          </div>
        {/if}
      </div>
    </div>
  </a>
</div>
