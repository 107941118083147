<script>
  import { getContext } from 'svelte';
  import Thumb from './icons/Thumb.svelte';
  import { translations } from '../../config/translations';

  const api = getContext('api');
  async function voteOnReview(event){
    const target = event.currentTarget;
    const voteDirection = target.dataset.vote;
    const currentCount = target.dataset.currentCount;
    const previousVote = localStorage.getItem(`${review.id}-review-vote`);

    if (previousVote){
      if (previousVote == voteDirection){
        return;
      } else if (previousVote == "up"){
        let currentUpvoteCount = parseInt(document.getElementById(`${review.id}-upvote-count`).textContent);
        document.getElementById(`${review.id}-upvote-count`).textContent = Math.max(currentUpvoteCount - 1, 0);
      } else if (previousVote == "down"){
        let currentUpvoteCount = parseInt(document.getElementById(`${review.id}-downvote-count`).textContent);
        document.getElementById(`${review.id}-downvote-count`).textContent = Math.max(currentUpvoteCount - 1, 0);
      }
    }
    
    if (voteDirection == "up"){
      document.getElementById(`${review.id}-upvote-count`).textContent = parseInt(currentCount) + 1;
      localStorage.setItem(`${review.id}-review-vote`, "up");
    } else if (voteDirection == "down"){
      document.getElementById(`${review.id}-downvote-count`).textContent = parseInt(currentCount) + 1;
      localStorage.setItem(`${review.id}-review-vote`, "down");
    }

    target.classList.add("voted");

    const result = await api.review_vote(review.id, voteDirection);
  }

  export let review;
</script>

<div class="review-votes">
  <div class="vote-text">
    {$translations.vote_question}
  </div>
  <div class="vote-actions">
    <div class="up-vote">
      <div class="thumb-image">
        <a href="{'javascript:void(0)'}" data-vote="up" data-current-count={review.upvotes_count || 0} on:click={voteOnReview}>
          <Thumb position="up" size=16 color="#888"/>
        </a>
      </div>
      <div id="{review.id}-upvote-count" class="vote-number upvote-count">{review.upvotes_count || 0}</div>
    </div>
    <div class="down-vote">
      <div class="thumb-image">
        <a href="{'javascript:void(0)'}" data-vote="down" data-current-count={review.downvotes_count || 0} on:click={voteOnReview}>
          <Thumb position="down" size=16 color="#888"/>
        </a>
      </div>
      <div id="{review.id}-downvote-count" class="vote-number downvote-count">{review.downvotes_count || 0}</div>
    </div>
  </div>
</div>