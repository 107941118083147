<script>
  import ReviewStars from '../../common/components/ReviewStars.svelte';
  import Star from '../../common/components/icons/Star.svelte';
  import { revConfig } from '../../config/storage';
  import { translations } from '../../config/translations';
  import { buildMsgFromTemplate } from '../../../util';

  export let product;
  const reviewBreakdown = product.review_data?.review_breakdown || {};
  const reviewStars = product.review_data?.stars || 0;
  const reviewsCount = product.review_data?.reviews_count || 0;
</script>

<div class="review-summary">
  <div class="review-summary-header">
    <div class="review-summary-title">
      {#if reviewStars > 0}
        <div class="summary-rating">{reviewStars.toFixed(1)}</div>
      {/if}
      <div class="review-summary-stars"><ReviewStars stars={reviewStars} starSize=20 /></div>
    </div>
    <div class="review-summary-total-reviews">
      {#if reviewStars > 0}
        {
          buildMsgFromTemplate($translations.review_summary_based_on_reviews, {
            reviews_count: product.review_data?.reviews_count
          })
        }
      {:else}
        {$translations.no_reviews_yet}
      {/if}
    </div>
  </div>
  {#if reviewBreakdown && $revConfig.widget_settings.display_summary_type == "breakdown"}
    <div class="review-breakdown-bars">
      {#each Array.from({length: 5}).map((_, i) => 5 - i) as starNumber}
        <div class="breakdown-bar">
          <div class="star-number">{starNumber}</div>
          <div class="star-icon">
            <Star size='full' starSize=14 starColor="{$revConfig.widget_css.theme_star_color}" />
          </div>
          <div class="star-bar">
            {#if reviewsCount > 0}
              <div class="star-bar-fill" style="width: {Math.round((reviewBreakdown[starNumber] / reviewsCount) * 100)}%"></div>
            {:else}
              <div class="star-bar-fill" style="width: 0%"></div>
            {/if}
          </div>
          <div class="star-count">
            {reviewBreakdown[starNumber] || 0}
          </div>
        </div>
      {/each}
    </div>
  {/if}
</div>
