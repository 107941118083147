import { $loyConfig, $tydal } from './storage';

class RequestHandler {
  constructor(
    {
      $appConfig = $loyConfig,
      includeShopParamOnRequests = false
    } = {}
  ) {
    this.includeShopParamOnRequests = includeShopParamOnRequests;
    this.$appConfig = $appConfig;
  }

  url(method, api_call, params = {}) {
    const baseUrl = new URL(this.$appConfig.api_endpoint, window.location.href);
    if (!/\/$/.test(baseUrl.pathname)) baseUrl.pathname += '/';

    const url = new URL(api_call, baseUrl);

    if (this.includeShopParamOnRequests) {
      url.searchParams.set('shop', $tydal.common.shop.permanent_domain);
    }

    if (method === 'GET') {
      url.pathname += '.json';
      for (const [key, value] of Object.entries(params)) {
        url.searchParams.set(key, value);
      }
    }

    return url;
  }

  async request(method, api_call, params = {}) {
    const url = this.url(...arguments);

    const headers = {
      'Content-Type': 'application/json'
    };

    let body;
    if (['POST', 'PUT'].includes(method)) {
      if (window.parent.Tydal?.preview_mode){
        body = JSON.stringify({});
      } else {
        body = JSON.stringify(params);
      }
    }

    try {
      const response = await fetch(url, { method, headers, body });
      if (response.status !== 200) {
        console.error(`API response status is ${response.status}`);
        return {};
      }
      return response.json();
    } catch (e) {
      console.error(e);
      return {};
    }
  }

  async requestWithFormData(method, api_call, params = {}) {
    const url = this.url(...arguments);

    const data = new FormData();
    for (const param in params) {
      const value = params[param];
      if (value) data.append(param, value);
    }
    try {
      const response = await fetch(url, { method: method, body: data });
      if (response.status !== 200) {
        console.error(`API response status is ${response.status}`);
        return {};
      }
      return response.json();
    } catch (e) {
      console.error(e);
      return {};
    }
  }
}

function emitPointsUpdate(pointsTally) {
  if (!pointsTally) return;
  window.parent.postMessage({ msg_action: 'event.points_update', msg_options: { points_tally: pointsTally } });
}

const API = function (requestHandler) {
  return {
    url(...args) {
      return requestHandler.url(...args);
    },
    rewards: async function () {
      const perPage = ($loyConfig.points_rewards?.length || 0) + 5
      const resp = await requestHandler.request('GET', 'loy/rewards', {per_page: perPage});
      var data = resp.rewards;
      if (!data) data = [];
      data = data.filter(function (el) {
        return el.enabled;
      });
      return data;
    },
    identify_customer: function (customer_id) {
      if (!customer_id) return Promise.resolve();
      return requestHandler.request('GET', `loy/customer/${customer_id}`, {}).then(function (resp) {
        return resp.customer;
      });
    },
    update_dob: function (customer_id, dob) {
      if (!customer_id || !dob) return Promise.resolve();
      return requestHandler.request('PUT', `loy/customer/${customer_id}`, {dob: dob}).then(function (resp) {
        if (resp && resp.success === true) return true;
        return false;
      });
    },
    points_logs: function (customer_id, page) {
      var def_data = {points_logs: [], metadata: {prev_page: null, next_page: null}};
      if (!customer_id) return Promise.resolve(def_data);
      var r_data = {customer_id: customer_id};
      if (page) r_data.page = page;
      return requestHandler.request('GET', `loy/customers/${customer_id}/points_logs`, r_data).then(function (data) {
        if (!data) data = def_data;
        return data;
      });
    },
    best_reward_to_show: function (customer_id) {
      if (!customer_id) return Promise.resolve();
      return requestHandler.request('GET', `loy/customers/${customer_id}/next_reward`, {}).then(function (resp) {
        return resp.reward;
      });
    },
    referrer_opt_in: function (email, first_name, last_name) {
      return requestHandler.request('POST', 'loy/opt_in_referrer', {
        shop: $tydal.common.shop.permanent_domain,
        email: email,
        first_name: first_name,
        last_name: last_name
      }).then(function (resp) {
        return resp;
      });
    },
    send_advocate_email_stats: function(email){
      return requestHandler.request('POST', 'loy/send_advocate_email_stats', {
        shop: $tydal.common.shop.permanent_domain,
        email: email
      }).then(function (resp) {
        return resp;
      });
    },
    referrals: function (customer_id, page) {
      if (!customer_id) return Promise.resolve();
      return requestHandler.request('GET', `loy/customers/${customer_id}/referrals`, {page: page}).then(function (resp) {
        return resp;
      });
    },
    referral_signup: function (email, referral_code, visitor_token, source, referral_campaign_id, accepts_marketing) {
      return requestHandler.request('POST', 'loy/referrals', {
        shop: $tydal.common.shop.permanent_domain,
        email: email,
        referral_code: referral_code,
        visitor_token: visitor_token,
        source: source,
        referral_campaign_id: referral_campaign_id,
        accepts_marketing: accepts_marketing
      }).then(function (resp) {
        return resp;
      });
    },
    referral_invite: function (emailTo, emailSubject, emailNote, customer_id, referral_campaign_id, send_reminder) {
      return requestHandler.request('POST', `loy/customers/${customer_id}/referral_invitation`, {
        shop: $tydal.common.shop.permanent_domain,
        email_recipients: emailTo,
        email_subject: emailSubject,
        email_note: emailNote,
        customer_id: customer_id,
        referral_campaign_id: referral_campaign_id,
        send_reminder: send_reminder
      }).then(function (resp) {
        return resp;
      });
    },
    grant_social_points: function (customer_id, social_action) {
      return requestHandler.request('POST', `loy/customers/${customer_id}/grant_social_earnings`, {
        social_action: social_action,
      }).then(function (resp) {
        if (resp && resp.success === true){
          emitPointsUpdate(resp.points_tally);
          return resp;
        }
      });
    },
    grant_visit_url_points: function (customer_id, url) {
      return requestHandler.request('POST', `loy/customers/${customer_id}/grant_visit_url_points`, {
        url: url,
      }).then(function (resp) {
        if (resp && resp.success === true){
          emitPointsUpdate(resp.points_tally);
          return resp;
        }
      });
    },
    points_purchases: function (customer_id, used, page) {
      var def_data = {points_purchases: [], metadata: {prev_page: null, next_page: null}};
      if (!customer_id) return Promise.resolve(def_data);
      var r_data = {customer_id: customer_id, used: used || false};
      if (page) r_data.page = page;
      return requestHandler.request('GET', 'loy/points_purchases', r_data).then(function (data) {
        if (!data) data = def_data;
        return data;
      });
    },
    redeem: function (customer_id, reward_name, points, credits) {
      if (!customer_id || !reward_name) return Promise.resolve();
      return requestHandler.request('POST', `loy/customers/${customer_id}/spend_points`, {reward_name, points, credits}).then(function (
        resp
      ) {
        if (resp) {
          emitPointsUpdate(resp.points_tally);
          return resp;
        }
      });
    },
    product: function (product_id) {
      return requestHandler.request('GET', `products/${product_id}`);
    },
    vip_tier: function (id) {
      return requestHandler.request('GET', `loy/vip_tiers/${id}`);
    },
    customer_tier: function (customer_id) {
      if (!customer_id || !$loyConfig.vip_program_enabled) return Promise.resolve();
      return requestHandler.request('GET', `loy/customers/${customer_id}/vip_tiers`, {}).then(function (resp) {
        return resp;
      });
    },
    membership_logs: function (customer_id) {
      if (!customer_id) return Promise.resolve();
      return requestHandler.request('GET', `loy/customers/${customer_id}/vip_memberships`, {}).then(function (resp) {
        return resp;
      });
    },
    reviews: function (product_id, page) {
      var def_data = {reviews: [], metadata: {prev_page: null, next_page: null}};
      var r_data = {product_id: product_id, page: (page || 1)};
      return requestHandler.request('GET', `reviews`, r_data).then(function (data) {
        if (!data) data = def_data;
        return data;
      });
    },
    create_review: function (options) {
      let url = 'reviews';
      if ($tydal.rev_order_id){
        url = $tydal.rev_config.api_endpoint;
      }
      return requestHandler.requestWithFormData('POST', url, {
        shop_id: $tydal.common.shop.id,
        shop: $tydal.common.shop.permanent_domain,
        title: options.title,
        body: options.body,
        first_name: options.first_name,
        last_name: options.last_name,
        stars: options.stars,
        email: options.email,
        product_id: options.product_id,
        product_title: options.product_title,
        product_handle: options.product_handle,
        visitor_token: options.visitor_token,
        image: options.image,
        cloudflare_stream_uid: options.cloudflare_stream_uid,
        order_id: options.order_id,
        draft: options.draft,
        draft_key: options.draft_key
      }).then(function (resp) {
        console.log(resp)
        return resp;
      }); 
    },
    review_vote: function (review_id, vote_direction) {
      return requestHandler.request('POST', `reviews/${review_id}/votes`, {
        visitor_token: window.parent.tyMet?.getVisitorToken(),
        vote_direction: vote_direction
      }).then(function (resp) {
        return resp;
      });
    }
  };
};

export function buildAPIClient(...args) {
  const requestHandler = new RequestHandler(...args);
  return API(requestHandler);
}
