<script>
  import PlayButton from '../../common/components/icons/PlayButton.svelte';
  import Thumb from '../../common/components/icons/Thumb.svelte';
  import SealCheck from '../../common/components/icons/SealCheck.svelte';
  import ReviewStars from '../../common/components/ReviewStars.svelte';
  import ReviewVoting from '../../common/components/ReviewVoting.svelte';
  import { revConfig } from '../../config/storage';
  import { translations } from '../../config/translations';
  import { renderDate, _truncate } from '../../../util';

  function openReviewModal(){
    window.parent.postMessage({msg_action: "ba.ratings.open_review", msg_options: {review: review}}, '*');
  }

  export let review;
  let image = review.image_urls ? review.image_urls[0] : null;
  let cloudflareStream = review.cloudflare_stream_urls ? review.cloudflare_stream_urls[0] : null;
  let thumbnail = image || cloudflareStream?.thumbnail;
</script>

<div class="list-item {thumbnail ? 'with-media' : 'no-media'}">
  <div class="review-sidebar">
    <div class="review-author-details">
      <div class="author-circle">
        {review.author[0]}
      </div>
      <div class="author-name-w-details">
        <div class="author-name">
          {review.author}
        </div>
        {#if $revConfig.widget_settings.display_verified_badge_enabled && review.verified}
          <div class="author-verified">
            <div class="verified-badge" title="Verified Purchase">
              <SealCheck size=16 fill="#000000" />
            </div>
            <div class="verified-text">
              {$translations.verified_customer}
            </div>
          </div>
        {/if}
      </div>
    </div>
    <div class="recommendation">
      {#if $revConfig.widget_settings.display_recommendations_enabled && review.is_recommended}
        <div class="recommendation-icon">
          <Thumb size=12 fill="#000000" />
        </div>
        <div class="recommendation-text">
          {$translations.is_recommended_text}
        </div>
      {/if}
    </div>
  </div>
  <div class="review-content">
    <div class="review-header">
      <div class="review-stars"><ReviewStars stars={review.stars} starSize=18/></div>
      {#if $revConfig.widget_settings.display_review_date_enabled}
        <div class="review-date">{renderDate(review.rated_at, $revConfig.widget_settings.display_date_type)}</div>
      {/if}
    </div>
    {#if review.title}<div class="review-title">{review.title}</div>{/if}
    <div class="review-body">{review.body}</div>
    {#if thumbnail}
      <div class="review-media">
        <div class="review-media-thumbnail">
          <a href="{'javascript:void(0)'}" on:click={openReviewModal}>
            {#if cloudflareStream}
              <div class="play-button-container">
                <div class="play-button-background">
                  <PlayButton size=32 />
                </div>
              </div>
            {/if}
            <img src="{thumbnail}" style="background:#fff;" alt="{review.id}">
          </a>
        </div>
      </div>
    {/if}
    {#if $revConfig.widget_settings.display_voting_enabled}
      <ReviewVoting review={review} />
    {/if}

    {#if review.reply && review.reply_is_public}
      <div class="review-reply">
        <div class="review-reply-text-intro">{$translations.shop_replied.replace('{{shop_name}}', window.parent.Tydal.global_config.shop.name)}</div>
        <div class="review-reply-body">{_truncate(review.reply, 250)}</div>
      </div>
    {/if}
  </div>
</div>
