import { derived } from 'svelte/store';

import { $revConfig, revConfig, storeProxy } from './storage';

function withAdditionalTranslations($translations) {
  if (!$translations) {
    return $translations;
  }

  return {
    ...$translations,
    social_instagram_follow_action: $translations.social_follow,
    social_tiktok_follow_action: $translations.social_follow,
    social_facebook_like_action: $translations.social_like,
    social_facebook_share_action: $translations.social_share,
    social_twitter_follow_action: $translations.social_follow,
    social_twitter_share_action: $translations.social_share
  };
}

export const translations = derived(revConfig, $revConfig => withAdditionalTranslations($revConfig.translations));
export const $translations = storeProxy(translations);
