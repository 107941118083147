<script>
  export let size;
  export let starSize;
  export let starColor = "#FFC107";
</script>

{#if size === "full" }
  <svg class="star" enable-background="new 0 0 24 24" height="{starSize}" width="{starSize}" fill="{starColor}" viewBox="3.44 3.57 17.11 16.36"><path d="m0 0h24v24h-24z" fill="none"/><path d="m0 0h24v24h-24z" fill="none"/><path d="m12 17.27 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72 3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41-1.89-4.46c-.34-.81-1.5-.81-1.84 0l-1.89 4.45-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08z"/></svg>
{:else if size == "half"}
  <svg class="star" enable-background="new 0 0 24 24" height="{starSize}" width="{starSize}" fill="{starColor}" viewBox="3.44 3.56 17.12 16.37"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19.65 9.04l-4.84-.42-1.89-4.45c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.73 3.67-3.18c.67-.58.32-1.68-.56-1.75zM12 15.4V6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path></svg>
{:else if size == "empty"}
  <svg class="star" enable-background="new 0 0 24 24" height="{starSize}" width="{starSize}" fill="{starColor}" viewBox="3.44 3.56 17.12 16.37"><path d="m0 0h24v24h-24z" fill="none"></path><path d="m19.65 9.04-4.84-.42-1.89-4.45c-.34-.81-1.5-.81-1.84 0l-1.89 4.46-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5 4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.73 3.67-3.18c.67-.58.32-1.68-.56-1.75zm-7.65 6.36-3.76 2.27 1-4.28-3.32-2.88 4.38-.38 1.7-4.03 1.71 4.04 4.38.38-3.32 2.88 1 4.28z"></path></svg>
{/if}
