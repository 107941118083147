<script>
  import { revConfig } from '../../config/storage';
  import Star from './icons/Star.svelte';
  export let stars;
  export let starSize = 14;
  let starColor = $revConfig.widget_css.theme_star_color || "#FFC107";

  let roundedStars = Math.round(stars * 2) / 2;
  if (stars % 1 > 0.7) {
    roundedStars = Math.ceil(roundedStars);
  }
  const fullStars = Math.floor(roundedStars);
  const halfStars = (roundedStars % 1 === 0.5) ? 1 : 0;
  const emptyStars = Math.max(0, 5 - fullStars - halfStars);
</script>

<div class="tydal-star-wrapper">
  {#each Array(fullStars) as _}
    <Star size='full' starColor="{starColor}" starSize={starSize} />
  {/each}
  {#if halfStars > 0}
    <Star size='half' starColor="{starColor}" starSize={starSize} />
  {/if}
  {#each Array(emptyStars) as _}
    <Star size='empty' starColor="{starColor}" starSize={starSize} />
  {/each}
</div>