import { derived, get, writable } from 'svelte/store';
import DisplayListPage from './pages/DisplayListPage';
import { getQueryParams} from "../../util"

let defaultPage = DisplayListPage;
const defaultPageStack = [{ page: defaultPage, props: {} }];

let params = getQueryParams();
let previewView = params["view_page"];
if (previewView) {
  window.preview_page = "DisplayListPage";
}

export const pageStack = writable(defaultPageStack);
export const currentPage = derived(pageStack, $pageStack => $pageStack[$pageStack.length - 1]);

export function visit(page, props = {}) {
  pageStack.update($pageStack => [...$pageStack, { page, props }]);
}

export function goBack() {
  if (get(pageStack).length <= 1) {
    throw 'Cannot go back; there are not multiple pages in the stack.';
  }

  pageStack.update($pageStack => $pageStack.slice(0, -1));
}

export function setCurrentPageProps(props) {
  pageStack.update($pageStack => [...$pageStack.slice(0, -1), { ...$pageStack[$pageStack.length - 1], props }]);
}

export function removePreviousPageFromStack() {
  pageStack.update($pageStack => [...$pageStack.slice(0, -2), ...$pageStack.slice(-1)]);
}

export function resetPageStack() {
  pageStack.set(defaultPageStack);
}
