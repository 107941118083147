import { waitForElement } from '../../util';

function resizeReview(reviewPanel, reviewEl) {
  if(!reviewEl.querySelector('.review-card')) return;

  const rowHeight = parseInt(window.getComputedStyle(reviewPanel).getPropertyValue('grid-auto-rows'));
  const rowGap = parseInt(window.getComputedStyle(reviewPanel).getPropertyValue('grid-row-gap'));
  const rowSpan = Math.ceil((reviewEl.querySelector('.review-card').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
  reviewEl.style.gridRowEnd = "span " + rowSpan;
}

function resizeAllReviews(reviewPanel) {
  const reviews = reviewPanel?.children;
  if(!reviews) return;
  for(var i=0; i<reviews.length; i++) {
    resizeReview(reviewPanel, reviews[i]);
  }
}

export function resizeDisplayListReviews(){
  window.parent.tyMet.log("resizeDisplayListReviews")
  const reviewPanel = document.getElementById('review-panel');
  waitForElement("#panel-container").then((selector) => {
    resizeAllReviews(reviewPanel);
    window.parent.postMessage({msg_action: "ba.ratings.resize", msg_options: {scroll_height: selector.scrollHeight}}, '*');
  });
}